
// On scan Submit
$(document).on('click','#articleScanSubmit', function() {

    let error = false;
    let notFound = [];
    let articleArray = '';
    let scannedItems = $('#scannedArticle').val().replace(/\s/g,''); // removes spaces

    articleArray = scannedItems.split(';');

    if(articleArray.length > 0) {

        articleArray.forEach(function (article) {

            // the filter function makes sure that only the label matching exactly the searched text are used
            let check = $('.td_checks')
                .find("label:contains("+article+")")
                .filter((a,e) => {return e.innerText === article});

            if(check.length > 0){

                check.siblings('.the_stat').prop('checked',true);

            }else if (article.length > 0) {

                error = true;
                notFound.push(article + ', ');
            }
        });

        if (error === false){
            closePopup();
        }else{

            $('#error_scan_handout_articles_numbers').html(notFound).show();
            $('#error_scan_handout_articles').show();
        }
    }
});


// Adding scanned articles

function articleScanInput() {

    let updated = $('#scannedArticle').val() + ';\n';
    $('#scannedArticle').val(updated);
};

$(document).on('input','#scannedArticle', _.debounce(articleScanInput, 250));

$(document).on('ready',function () {

    // scan Article
    $('#selectByScan').on('click',function () {

        $.ajax({
            url : 'includes/artikel/ajax/scanArticleForSelect.php',
            type: 'POST',
            data: {
                method : 'ScanArticle'
            },
            success: function(data) {
                openPopup(data, '500px');
            }
        })
    });
});
