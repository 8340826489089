quickverleih = quickverleih || {};

quickverleih.storage = (function () {

    var set_inteval_for_insert_article = function(startDay, startTime, endDate, endTime) {
        localStorage.setItem('InsertArticleStartDate', startDay);
        localStorage.setItem('InsertArticleStartTime', startTime);
        localStorage.setItem('InsertArticleEndDate', endDate);
        localStorage.setItem('InsertArticleEndTime', endTime);
    }

    var get_inteval_for_insert_article = function() {
        return {
            start_date: localStorage.getItem("InsertArticleStartDate"),
            start_time: localStorage.getItem("InsertArticleStartTime"),
            end_date: localStorage.getItem("InsertArticleEndDate"),
            end_time: localStorage.getItem("InsertArticleEndTime")
        }
    }

	// for now simple storage
    return {
        set_inteval_for_insert_article,
        get_inteval_for_insert_article,
    };
})();

