
quickverleih.api = (function () {

    function _ajaxCallServer(url, type, data, callback) {
        $.ajax({
            url     : url,
            type    : type,
            data    : data,
            datatype: 'application/json',
            success : function (data) {
                if(typeof(callback) == 'function') {
                    callback(data);
                }
            },
            error: function (xhr) {
                if(typeof(callback) == 'function') {
                    callback(); // undefined
                }
            }
        });
    }

    function callServer(url, type, data, callback) {
        data = data || {};

        if(typeof(callback) == 'function') {
            _ajaxCallServer(url, type, data, callback);
        } else {
            return new Promise(resolve => {
                _ajaxCallServer(url, type, data, resolve);            
            });
        }
    }

    // a shortcut for render php file, use quickverleih.template.render to render a js side script
    var render_php = function (url, data) {
        return callServer(url, 'POST', data);
    }

    return {
        callServer: callServer,
        render_php
    }
})();