
quickverleih.employees = quickverleih.employees || {};

quickverleih.employees.api = (function () {

    function addEmployee(data, callback) {

        quickverleih.api.callServer('includes/employees/api/index.php?method=create_employee', 'POST', data, callback);
    }

    function editEmployee(data, callback) {

        quickverleih.api.callServer('includes/employees/api/index.php?method=edit_employee', 'POST', data, callback);
    }

    function showEmployee(employeeId, callback) {

        var data = {
            employee_id: employeeId
        };

        quickverleih.api.callServer('includes/employees/api/index.php?method=show_employee', 'GET', data, callback);
    }

    function deleteEmployee(employeeId, callback) {

        var data = {
            employee_id: employeeId
        };

        quickverleih.api.callServer('includes/employees/api/index.php?method=delete_employee', 'GET', data, callback);
    }

    return {
        // addEmployee   : addEmployee,
        // editEmployee  : editEmployee,
        deleteEmployee: deleteEmployee,
        showEmployee  : showEmployee
    };
})();