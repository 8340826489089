
quickverleih = quickverleih || {};

quickverleih.template = (function () {

    function _compile_template(template) {
        // check the cache first
        var compiled = false;

        if(compiled === false) {
            var template_content = '';

            // get the template file, sync
            $.ajax({
                async  : false,
                url    : template,
                success: function (result) {

                    if (typeof(result) !== 'undefined' && result != "") {
                        template_content = result;
                    }
                }
            });

            compiled = _.template(template_content);
        }

        return compiled;
    }

    function render(template, params) {
        // use name for caching
        // if (typeof name == 'undefined') { name = _hash_code(template); }

        var compiled = _compile_template(template);

        params.partial = render;

        return compiled(params);
    }

    return {
        render: render
    }
})();

