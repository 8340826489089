quickverleih.article = quickverleih.article || {};

quickverleih.article.api = (function () {
    function getKeyForTenantAndLocation(tenant_id, location_id, callback) {
        return quickverleih.api.callServer('api/v2/index.php?method=get_return_key&tenant_id='+tenant_id+'&location_id='+location_id, 'GET');
    }
    
    return {
        getKeyForTenantAndLocation   : getKeyForTenantAndLocation
    }

})();