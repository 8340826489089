/* global quickverleih, $ */

quickverleih = quickverleih || {}

quickverleih.editor = (function () {

    function init(selector_id, $submitButton = null, placeholder = "", onChange = null) {
        $(selector_id)
            .summernote({
                lang: 'de-DE',
                height: 200,
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'superscript', 'subscript', 'clear']],
                    ['format', ['fontsize', 'color']],
                    ['para', ['paragraph']],
                    ['insert', ['link']]
                    // ['delimitor', ['hr']],
                    // ['table', ['table']]
                    // ['code', ['codeview']]
                ],
                disableResizeEditor: true,
                placeholder: placeholder
            })
            .on('summernote.change', function(we, content, $editable) {
                // console.log('summernote changed', content);

                if($submitButton) {
                    $submitButton
                        .removeClass('submit-button-disabled')
                        .addClass('submit-button')
                        .enable();
                }

                if(onChange) {
                    onChange(content);
                }
            });
    }

    return {
        init: init
    }
})();