
quickverleih.settings = quickverleih.settings || {};
quickverleih.settings.mahnung = (function () {

    const TIME_INTERVAL_DAY = 'day';
    const TIME_INTERVAL_HOUR = 'hour';
    const TIME_INTERVAL_HALFHOUR = 'halfhour';
    const TIME_INTERVAL_QUARTERHOUR = 'quarterhour';

    let _selector = '.warning-table .custom-table';
    var _content = null;

    function init() {
        _content = $(_selector);
        
        _disableEnableInputsBySelectValues();

        _timeIntervalChanged();
    }
    
    function _disableEnableInputs($el) {
        let timeIntervalSelected = $el.val();
        
        $input = $el.siblings("input.mahnung_zeit");
        if(timeIntervalSelected === TIME_INTERVAL_HALFHOUR || timeIntervalSelected === TIME_INTERVAL_QUARTERHOUR) {
            $input.val(1);
            $input.attr('readonly', true);
        } else {
            $input.attr('readonly', false);
        }
    }
    
    function _disableEnableInputsBySelectValues() {
        var timeIntervalSelects = _content.find('.time-interval');
        $.each(timeIntervalSelects, function( i, select ) {
            $el = $(select);
            _disableEnableInputs($el);
        });
    }

    function _timeIntervalChanged() {
        var timeIntervalSelect = _content.find('.time-interval');
        timeIntervalSelect.off('change').on('change', (e) => {
            $el = $(e.target);
            _disableEnableInputs($el);
        });
    }

    return {
        init: init
    };
})();
