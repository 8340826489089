
function get_send_email_parameters(customer_address_id, pdf_type) {
	// idcontract and contract_type is a global variable :(
	post_parameters = {}
	post_parameters.customer_address_id = customer_address_id;
	post_parameters.contract_type =
		(typeof (pdf_type) != "undefined" && pdf_type.indexOf("reminder") == 0) ? 6 /* reminder */ : contract_type;
	post_parameters.idcontract = idcontract;
	post_parameters.pdf_type = pdf_type;

	return post_parameters;
}

function handle_send_email_response(data) {
	// location.reload();
	alert(unescape(data.message));
}

function send_email(customer_address_id, pdf_type) {
	post_parameters = get_send_email_parameters(customer_address_id, pdf_type);

	$.post("includes/vertraege/ajax/send_email.php",
		post_parameters,
		function (data) {
			handle_send_email_response(data);
		},
		"json"
	);
}

function openPopup(html, width, showClose = true) {
	var popup = $('#popup');

	popup.html(html);

	// open modal with custom settings
	popup.modal({
		clickClose: false,
		showClose: showClose,
		escapeClose: false
	});

	// on leaving, ask for confirmation if form is not saved
	popup
		.off("click.modal", 'a[rel~="modal:close"]')
		.on("click.modal", 'a[rel~="modal:close"]', function (e) {
			if ($('.save-button', popup).hasClass('submit-button') && !$('.save-button', popup).hasClass('submit-button-disabled')
				&& !$("#popup").find('.in-contract-popup').html()) {

				if (confirm('Möchten Sie das Popup schliessen?')) {
					// close and disable save button
					$('.save-button', popup).removeClass('submit-button');
					$('.save-button', popup).addClass('submit-button-disabled');
				} else {
					e.preventDefault();
					return false;
				}
			}
		});
	popup.width(width);

	return popup;
}

function openPopupOverPopup(html, width, callback = false) {
	$('#popup1').html(html);
	$('#popup1').width(width).css('overflow', '');

	// open modal with custom settings
	$('#popup1').modal({
		closeExisting: false,
		escapeClose: false
	});

	$("#popup1")
		.off("click.modal")
		.on("click.modal", 'a[rel~="modal:close"]', function (e) {
			if (callback) {
				callback();
			}
		});

	return false;
}

function closePopup() {
	$('.close-modal').click();
	return false;
}

/*
* Convert string date to d.m.Y format
* 				   	  or d-m-Y
*				   	  or Y-m-d
*				   	  or Y.m.d
*/
function convertStringDate(stringDate, format) {
	var today = new Date(stringDate);
	var dd = today.getDate();
	var mm = today.getMonth() + 1; //January is 0!
	var yyyy = today.getFullYear();

	var hr = today.getHours().toString().padStart(2, '0');
	var mn = today.getMinutes().toString().padStart(2, '0');

	if (dd < 10) {
		dd = '0' + dd
	}

	if (mm < 10) {
		mm = '0' + mm
	}

	switch (format) {
		case 'd.m.Y': today = dd + '.' + mm + '.' + yyyy; break;
		case 'd-m-Y': today = dd + '-' + mm + '-' + yyyy; break;
		case 'Y.m.d': today = yyyy + '.' + mm + '.' + dd; break;
		case 'Y-m-d': today = yyyy + '-' + mm + '-' + dd; break;
		case 'Y-m-d H:i': today = yyyy + '-' + mm + '-' + dd + ' ' + hr + ':' + mn; break;
		case 'H:i': today = hr + ':' + mn; break;
	}

	return today;
}

$(document).ready(function () {
	/*
	* NAVIGAION
	*/
	// toggle navigation menus
	$('.main-nav-items li').click(function () {
		$(this).toggleClass('main-nav-activated');
		$('#sub_nav_' + $(this).attr('nav')).toggle();
	});

	// on the login page focus on the user name
	$("#i_bname").focus();

	$(".clientRestriction").click(function () {
		var self = $(this);
		var addRemove = 0;//remove default

		if (self.is(":checked")) {//if checked
			self.attr('checked', 'checked');
			addRemove = 1;//add

		} else {
			self.removeAttr("checked");
			addRemove = 0;//remove
		}

		var dataToSend = {
			id_category: $(this).attr("data-idcategory"),
			id_client: $(this).attr("data-idclient"),
			operation: addRemove
		};
		$.ajax({
			type: "POST",
			url: "includes/kunden/ajax/add_remove_category.php",
			data: dataToSend,
		})
	});

	/* vvv ENABLE SAVE BUTTONS ON FORM CHANGE */
	$(
		'#settingsOrganisationForm'
		+ ',' +
		'#settingsShopForm'
		+ ',' +
		'#settingsAnfrageForm'
		+ ',' +
		'#settingsReservationForm'
		+ ',' +
		'#settingsSystemForm'
		+ ',' +
		'#settingsMahnungForm'
		+ ',' +
		'#settingsForm'

	).on("keyup change", function () {
		var buttonId = $(this).find('.save-button').attr('id');
		enableSaveButton('#' + buttonId);
	});

	// this method is used for save buttons loaded by ajax
	$(document).on("keyup change", '#articleForm, #customerForm', function () {
		var buttonId = $(this).find('.save-button').attr('id');

		// check for errors
		var errors = false;
		$.each($(this)[0], function (index, value) {
			//console.log(value);
			if ($(value).hasClass('red') || $(value).hasClass('field_error')) {
				errors = true;
				//console.log(value);
			}
		});

		if (errors === false) {
			enableSaveButton('#' + buttonId);
		} else {
			disableSaveButton('#' + buttonId);
		}

	});
	/* ^^^ ENABLE SAVE BUTTONS ON FORM CHANGE */

});

function enableSaveButton(buttonId) {

	if ($(buttonId).hasClass('submit-button-disabled') && !$('.warning-error')[0]) {

		$(buttonId)
			.removeClass('submit-button-disabled')
			.addClass('submit-button')
			.removeAttr('disabled');
	}

}

function disableSaveButton(buttonId) {

	if ($(buttonId).hasClass('submit-button')) {

		$(buttonId)
			.removeClass('submit-button')
			.addClass('submit-button-disabled')
			.attr('disabled', 'disabled');

	}

}

function charsInArray(text, acceptable) {

	var allowed = true;

	// Loop through each character in the string
	var length = text.length;
	for (var i = 0; i < length; i++) {
		// This if condition is fulfilled if the character
		// is not in the array of acceptable characters
		if (jQuery.inArray(text[i], acceptable) < 0)
			allowed = false;
	}

	return allowed;
}

// .............................................................................
// triggers popup for QR code from the dashboard top bar button
// .............................................................................
function qr__search(qr_srch) {
	if (qr_srch == '' || qr_srch === undefined || qr_srch === null) { return false; }

	var html = ''
		+ '<div class="qr_data">'
		+ '<div class="col-max-md-one-full qr_wrap">'

		+ '<div id="qr_html" style="width:100%; display:block;">'
		+ '<div style="margin:25px auto; padding:15px; text-align:center; border-top:1px solid #DDD; border-bottom:1px solid #DDD;">... loading data ...</div>'
		+ '</div>'

		+ '</div>'
		+ '</div>'
		;

	openPopup(html, '1200px');

	$('.qr_data').parent().addClass('rounded');

	var dataHtml = $('.qr_data').find('#qr_html');

	$.ajax({
		url: 'includes/vertraege/ajax/ContractArticlesQR.php',
		type: 'POST',
		data: { 'qr_srch': qr_srch },
		success: function (data) {
			dataHtml.html(data);
			$('#qrCheck').html(qr_srch);

			var dataTablesOptions = setDataTablesOptions($('#QRTableWrapper').find('.data-table-block'));
			var data_table = $('#qr_fill').DataTable(dataTablesOptions);

			// .................
			// sorting by column
			// .................
			data_table.column('4').order('desc').draw();

			$('.reordered').each(function (index, eleme) {

				var show_date = $(this).attr('data-show');
				//console.log('show_date: '+show_date);
				var used_date = $(this).find('span').html();
				//console.log('used_date: '+used_date);
				$(this).find('span').html(show_date);
				$(this).attr('data-show', used_date);
			});
			// .................
		}
	});
}
function qr_doValue() {
	var qr_srch = $('.qr_pops input[type="search"]').val();
	//console.log('qr_srch: '+qr_srch);

	if (qr_srch != '') {
		closePopup();
		qr__search(qr_srch);
	}
}
function qr_codeGet() {
	if ($('#qr_pops').length)
		$('#qr_pops').on('click', function (e) {
			e.preventDefault();

			var html = ''
				+ '<div class="qr_pops">'
				+ '<div class="col-max-md-one-full qr_wrap">'

				+ '<div class="qr_col1 qr_icon">'
				+ '<svg class="icon">'
				+ '<use xlink:href="#icon-qr-code"></use>'
				+ '</svg>'
				+ '<center>Artikel jetzt scannen</center>'
				+ '</div>'

				+ '<div class="qr_col1 qr_midl">'
				+ '- oder -'
				+ '</div>'

				+ '<div class="qr_col3">'
				+ '<div class="data-table-block dash-block-container">'

				+ '<div class="data-table-header dash-block-header flex">'
				+ '<div class="data-table-name text-uppercase">Artikelnummer Eingeben</div>'
				+ '</div>'

				+ '<div class="data-table-content">'

				+ '<div class="dataTables_filter">'
				+ '<label>'
				+ '<input id="qr_area" placeholder="" type="search" />'
				+ '</label>'
				+ '<svg class="icon">'
				+ '<use xlink:href="#icon-search"></use>'
				+ '</svg>'
				+ '</div>'

				+ '<div class="button submit-button text-uppercase button-margins" id="qr_srch">Informationen Abrufen</div>'

				+ '</div>'

				+ '</div>'
				+ '</div>'

				+ '</div>'
				+ '</div>'
				;

			openPopup(html, '470px');

			$('.qr_pops').parent().addClass('rounded');

			$('#qr_area').focus();

			$('.qr_pops .submit-button').on('click', function (e) {
				qr_doValue();
			});
			$(document).keypress(function (e) {
				if (e.which == 13) {
					e.preventDefault();
					$('.qr_pops .submit-button').trigger('click');
				}
			});
		});
}
$(function () {
	//qr__search('AC0');
	qr_codeGet();
});
// .............................................................................



// .............................................................................
// helps on month selector at dashboard
// .............................................................................
$(function () {
	function prevNextMonthYear(delta, defaultForPrevNextMonth, isMonthValid) {
		var selectedMonth = $('#table-stats-month-data').val();
		var prevNextMonth = parseInt(selectedMonth) + delta;

		let monthYearSelectionUpdated = false;

		// if prev/next month is valid, select it
		if (isMonthValid(prevNextMonth)) {
			$('#table-stats-month-data').val(prevNextMonth);

			monthYearSelectionUpdated = true;
		} else {
			var currentYear = $('#table-stats-year-data').val();
			var prevNextYear = parseInt(currentYear) + delta;

			// if the prev/next year exists select it, as well as the default month for that year
			if ($(`#table-stats-year-data option[value='${prevNextYear}']`).length > 0) {
				$('#table-stats-year-data').val(prevNextYear);
				$('#table-stats-month-data').val(defaultForPrevNextMonth);

				monthYearSelectionUpdated = true;
			}
		}

		return monthYearSelectionUpdated;
	}

	if ($('#table-stats-month-data').length) {
		$('.month-pre').live('click', function (ev) {
			ev.preventDefault();

			if (prevNextMonthYear(-1, 12, (prevNextMonth) => prevNextMonth >= 1)) {
				$('#table-stats-month-data').trigger('change');
			}
		});

		$('.month-nxt').live('click', function (ev) {
			ev.preventDefault();

			if (prevNextMonthYear(1, 1, (prevNextMonth) => prevNextMonth <= 12)) {
				$('#table-stats-month-data').trigger('change');
			}
		});
	}
});
// .............................................................................

// shows some cutom alert
function showAlert(message, callback = false) {
	var html = ''
		+ '<div class="dash-block-header error-popup">'
		+ '<div class="message-header-title text-uppercase">'
		+ 'Fehler'
		+ '</div>'
		+ '</div>'
		+ '<div class="popup-content">'
		+ '<br><p><b>' + message + '</b></p><br>'
		+ '</div>'
		;

	openPopupOverPopup(html, '500px', callback);
}

// export section
window.openPopup = openPopup;
window.openPopupOverPopup = openPopupOverPopup;
window.closePopup = closePopup;
window.convertStringDate = convertStringDate;
window.enableSaveButton = enableSaveButton;
window.disableSaveButton = disableSaveButton;
window.charsInArray = charsInArray;
window.showAlert = showAlert;
window.send_email = send_email;
