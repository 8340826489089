/* global $, enableSaveButton */

/*

$(document).ready(function() {
	let subCounter = 0;
	let tempids = [];
  
	/*
	* Change Tenant
	*/
	// $('#articleTenantId').change(function() {

	let subCounter = 0;
	let tempids = [];

	$(document).on('change', '#articleTenantId', function() {
		let newTenant = $('#articleTenantId').val();
		articleLocationsSelectReload(newTenant);
		
	})

	/* Article Locations reload */
	var articleLocationsSelectReload = function (tenant_id) {

		$.ajax({
			url: 'includes/artikel/ajax/ArticleLocationSelect.php',
			type: 'POST',
			data: {
				"article_id": 0,
				"tenant_id": tenant_id,
				"warehouse_location_id": 0,
			},
			success: function (data) {
				// Reload subarticles location select
				let $subarticleWarehouseLocationSelect = $('.main-content.container select.warehouse_location');
				$subarticleWarehouseLocationSelect.empty().append(data);
			}
		});
	}

	$(document).on('click', '#add_subarticle_number', function() {

		tempids = $('#new_subarticle_ids').val().split(',');
        // Adds 1 to Lagerbestand

        $('#articleQty').val( function(i, oldval) {
            return ++oldval;
        });

        // let articleId = $('#articleId').val();
		let tenantId = $('#articleTenantId').val();
        // Creates new Sub-article

		let serieNr;
		let subArticleTable = $('#subArticleTable').DataTable();
		let articleGNR = $('#articleNumber').val().toUpperCase();

		if(subArticleTable.data().count() === 0) {

			serieNr = '1';
		} else {
			var maxSubarticleNumber = 0; 
			$("input.subarticle-number").each(function() { maxSubarticleNumber = Math.max(maxSubarticleNumber, parseInt($(this).val())) });

			serieNr = maxSubarticleNumber + 1;
		}

		subCounter ++;
		let tempSubId = 'new_subId_'+subCounter ;

		tempids.push(tempSubId);
		$('#new_subarticle_ids').val(tempids);

		subArticleAdd(tenantId, tempSubId, serieNr, articleGNR);
	});

   $(document).on('click','#articleHasSubarticles',function() {
		var subs_visible = true;

		if($('#articleHasSubarticles').is(':checked')) {
			$('.basic_info_entry.article_price_holder').hide();

            $("label[for='articleHasSubarticles']").removeClass('color-warning');
			$('.subArticlesContainer').removeAttr('hidden');

			let tenantId = $('#articleTenantId').val();
			let qty = $('#articleQty').val();
			let serieNr = 0;

			// generate sub articles rows
			if(qty > 0 && $('#subArticleTable').DataTable().data().count() < qty){
				let leftToAdd = qty - $('#subArticleTable').DataTable().data().count();

				for (let i = 0, p = Promise.resolve(); i < leftToAdd; i++) {
					p = p.then(_ => new Promise(resolve =>
						setTimeout(function () {

							let tempSubId = 'new_subId_' + subCounter;
							let articleGNR = $('#articleNumber').val().toUpperCase();
							subCounter ++;
							serieNr++;
							tempids.push(tempSubId);
							$('#new_subarticle_ids').val(tempids);

							subArticleAdd(tenantId, tempSubId, serieNr, articleGNR,
								() => {
									resolve();
								});
						}, 0)
					));
				}
			}

			// also disable the stock count
			$('#articleQty').attr('readonly', '')

		} else if (! $('#articleHasSubarticles').is(':checked') && ! $('#subArticleTable').DataTable().data().count()) {
			// if has subs checkbox is not selected and there are no subs declared
            $("label[for='articleHasSubarticles']").removeClass('color-warning');
			$('.subArticlesContainer').attr('hidden',true);
			
			$('#articleQty').removeAttr('readonly');

			subs_visible = false
		} else {
			// tries to uncheck the has subs checkbox, but subs are already defined - they need to be manually deleted first
            $('#articleHasSubarticles').prop('checked', true);
			// $("label[for='articleHasSubarticles']").addClass('color-warning');
			// $('#articleHasSubarticles').parent().attr('title','Nicht änderbar, da Artikel bereits Unterartikel hat');
		}

		// hide the select for warehouse location on article level if subs are showing
		if(subs_visible) {
			$("#warehouse_location_at_article_level").hide();
			$("#storage_place_at_article_level").hide();
			$("#storage_compartment_at_article_level").hide();

			// removes selection
			$("#warehouse_location").val(0);
			$("#storagePlace").val("");
			$("#storageCompartment").val("");
		} else {
			$("#warehouse_location_at_article_level").show();
			$("#storage_place_at_article_level").show();
			$("#storage_compartment_at_article_level").show();
		}
   });

	// If the subarticle numbers are generated, do not allow any LAGER changes. Only from the Artikelnummern tab
	// show & hide popup message
	$("#article_lager").click(function () {
		var subart_count = $('#subart_count').val();

		if (subart_count > 0) {
			$('.lager-readonly-message').toggle().delay(7000).fadeOut('slow');
		}
	});
// Andy: check merge - why was this commented out - just the document ready part 
// });


function subArticleAdd(tenantId, subArticleId, serieNr, articleGNR, cb = () => {}) {

    $.ajax({
        url : 'includes/artikel/ajax/NewSubarticleRow.php',
        type: 'POST',
        data: {
			tenantId: tenantId,
			subArticleId : subArticleId.trim(),
			serieNr : serieNr,
			articleGNR : articleGNR,
        },
        success: function(data) {
			let obj = $(data);

			let table = $('#subArticleTable').DataTable();
			table.row.add(obj);
			table.order([0, 'desc']);

			table.draw();

			enableSaveButton('#save_article');

			cb();
        }
    });

}


$(document).on('click', '#export_ean_codes', function(e) {
	e.preventDefault();

	let articleId = $('#articleId').val();

	window.open('includes/artikel/ajax/eanExport.php?id='+articleId, '_blank');

	return false;
});