
quickverleih.article_numbers = quickverleih.article_numbers || {};

quickverleih.article_numbers.api = (function () {

    // function example(articleId, articleNumber, subarticlesNumbers) {

    //     let data = {
    //         method: 'example',
    //         articleId,
    //         articleNumber,
    //         subarticlesNumbers
    //     };

    //     return quickverleih.api.callServer('includes/artikel/ajax/ajaxArticle.php', 'POST', data);
    // }

    // return {
    //     example
    // };
})();