/* global $, _, quickverleih, showAlert, openPopup, closePopup */

quickverleih.settings = quickverleih.settings || {};
quickverleih.settings.system = (function () {

    let _selector = '#locations_table';
    var _content = null;
    
    let _tenantSelector = '#tenants_table';
    var _tenantContent = null;

    function init() {
        _content = $(_selector);

        _add_new_location();
        _edit_location()
        _delete_location();

        _tenantContent = $(_tenantSelector);

        _add_new_tenant();
        _edit_tenant()
        _delete_tenant();
    }

    // START TENANT SECTION

    function _show_add_new_tenant_popup(callback_after_tenant_add) {
        var popupContent = quickverleih.template.render('./includes/einstellung/templates/tenant_popup._', {
            mode : "",
            tenant: {},
        });

        let popup = openPopup(popupContent, '400px');

        let add_button = popup.find('.save-tenant');
        add_button.off('click').on('click', function () {

            if (_validateTenantInputs(popup) === false) {
                return;
            }

            let tenant_data = _get_tenant_popup_data(popup);
            quickverleih.settings.api.addTenant(tenant_data, (server_response) => {
                if(!server_response.ok) {
                    var message = server_response.message;
                    showAlert(message);
                    return false;
                }

                callback_after_tenant_add(server_response.data);

                closePopup();
            });
        });
    }

    function _add_new_tenant() {

        var _tenant_added_successfull = (server_response_data) => {
            

            var tenant_table = _tenantContent;
            var dataTable = tenant_table.DataTable();

            let edit_button = 
                `<button type="button"
                    data-tenant-id="${server_response_data.id}"
                    class="reset-button data-table-content-action-button edit-tenant">

                    <svg class="icon" style="width: 18px; height: 18px;">
                        <use xlink:href="#icon-pen"></use>
                    </svg>
                </button>`;
            let delete_button = 
                `<button type="button"
                    data-tenant-name="${server_response_data.name}" 
                    data-tenant-id="${server_response_data.id}"
                    class="reset-button x-button delete-tenant">

                    <svg class="icon" style="width: 13px; height: 13px;">
                        <use xlink:href="#icon-x"></use>
                    </svg>
                </button>`;

            dataTable.row.add([
                server_response_data.name,
                server_response_data.address,
                server_response_data.tel,
                server_response_data.fax,
                server_response_data.email,
                edit_button,
                delete_button,
            ]).draw(false);

            // reload these 2 functions
            _edit_tenant();
            _delete_tenant();
        }

        var _on_add_new_tenant = function () {
            // FOR TENANT: show add new tenant popup or contact QV support
            _show_add_new_tenant_popup(_tenant_added_successfull);
        };

        var new_tenant_button = _tenantContent.find('.add-new-tenant');
        new_tenant_button.off('click').on('click', _on_add_new_tenant);
    }

    function _edit_tenant() {

        var _tenant_saved_successfull = ($trParent, server_response) => {
            if(!server_response.ok)
            {
                var message = server_response.message;
                showAlert(message);
                return false;
            }

            var tenant_table = _tenantContent;
            var dataTable = tenant_table.DataTable();
            var rowData = dataTable.row($trParent).data();

            rowData[0] = server_response.data.name;
            rowData[1] = server_response.data.address;
            rowData[2] = server_response.data.tel;
            rowData[3] = server_response.data.fax;
            rowData[4] = server_response.data.email;

            dataTable.row($trParent).data(rowData).draw(false);

            // reload these 2 functions
            _edit_tenant();
            _delete_tenant();
           
            closePopup();
        }

        var _on_edit_tenant = function () {
            let $this = $(this);
            var tenant_id = $this.data("tenant-id");

            quickverleih.settings.api.getTenant(tenant_id, (response) => {
                if(!response.ok) {
                    var message = response.message;
                    showAlert(message);
                    return false;
                }

                let tenant_data = response.tenant;

                var popupContent = quickverleih.template.render('./includes/einstellung/templates/tenant_popup._', {
                    mode : "edit",
                    tenant: tenant_data,
                });
                
    
                let popup = openPopup(popupContent, '400px');
    
                let save_button = popup.find('.save-tenant');
                save_button.off('click').on('click', function () {
    
                    if (_validateTenantInputs(popup) === false) {
                        return;
                    }
    
                    let edited_tenant_data = _get_tenant_popup_data(popup);
                    edited_tenant_data.id = tenant_id;

                    quickverleih.settings.api.saveTenant(edited_tenant_data,
                        (server_response) => {
                            _tenant_saved_successfull($this.parents('tr').first(), server_response);
                        }
                    );
                });
            });
        };

        var edit_tenant_button = _tenantContent.find('.edit-tenant');
        edit_tenant_button.off('click').on('click', _on_edit_tenant);
    }

    function _validateTenantInputs(popup) {

        let ids_to_check = ['#tenant_name', '#tenant_address', '#tenant_tel', '#tenant_email'];

        let borderKO = '#ff0000';
        let borderOk = '#eaeaea';
        let anyInputEmpty = false;
        
        ids_to_check.map(input_id => {
            let $input = popup.find(input_id);
            let isEmpty = $input.val().length === 0;
            if(isEmpty) {
                $input.css({ 'border': '1px solid ' + borderKO });
            } else {
                $input.css({ 'border': '1px solid ' + borderOk });
            }

            anyInputEmpty  = anyInputEmpty || isEmpty;
        });

        return !anyInputEmpty;
    }

    function _get_tenant_popup_data(popup) {

        let data_elements = ['name', 'address', 'tel', 'fax', 'email'];
        let popup_data = {};

        _.each(data_elements, (element) => {
            let $input = popup.find(`#tenant_${element}`);

            popup_data[element] = $input.val()
        });

        return popup_data;
    }

    
    function _delete_tenant() {

        var _canDeleteTenant = function () {
            var $elem = $(this);

            var tenant_id = $elem.data('tenant-id');

            quickverleih.settings.api.canDeleteTenant(tenant_id, function (response) {
                if(!response.ok) {
                    var message = response.message;
                    showAlert(message);
                    return false;
                }

                if(!response.canDelete) {
                    var popupCantDeleteTenantContent = quickverleih.template.render('./includes/einstellung/templates/cant_delete_tenant_is_used_popup._', {});
                    openPopup(popupCantDeleteTenantContent, '500px');
                } else {
                    _deleteTenant($elem)
                }
            });
        }

        var _deleteTenant = function ($elem) {
            // var $elem = $(this);

            var tenant_id = $elem.data('tenant-id');

            var popupContent = quickverleih.template.render('./includes/einstellung/templates/delete_tenant_popup._', {});

            var popup = openPopup(popupContent, '500px');

            var confirm_delete_button = popup.find('.delete-tenant-yes');

            confirm_delete_button.off('click').on('click', function () {

                quickverleih.settings.api.deleteTenant(tenant_id, function (response) {
                    if(!response.ok) {
                        var message = response.message;
                        showAlert(message);
                        return false;
                    }

                    var $trParent = $elem.parents('tr').first();

                    var tenants_table = _tenantContent;
                    var dataTable = tenants_table.DataTable();

                    dataTable.row($trParent).remove().draw(false);

                    closePopup();
                });
            });
        };

        var delete_tenant_button = _tenantContent.find('.delete-tenant');
        delete_tenant_button.off('click').on('click', _canDeleteTenant);
    }

    // END TENANT SECTION

    function _show_add_new_location_popup(tenants, callback_after_location_add) {
        var popupContent = quickverleih.template.render('./includes/einstellung/templates/location_popup._', {
            mode : "",
            tenants: tenants || [],
            location: {},
        });

        let popup = openPopup(popupContent, '400px');

        let add_button = popup.find('.save-location');
        add_button.off('click').on('click', function () {

            if (_validateInputs(popup) === false) {
                return;
            }

            let location_data = _get_popup_data(popup);
            quickverleih.settings.api.addLocation(location_data, (server_response) => {
                if(!server_response.ok) {
                    var message = server_response.message;
                    showAlert(message);
                    return false;
                }

                callback_after_location_add(server_response.data, tenants);

                closePopup();
            });
        });
    }

    function _add_new_location() {

        var _location_added_successfull = (server_response_data, tenants) => {

            var locations_table = _content;
            var dataTable = locations_table.DataTable();

            let edit_button = 
                `<button type="button"
                    data-location-id="${server_response_data.id}"
                    class="reset-button data-table-content-action-button edit-location">

                    <svg class="icon" style="width: 18px; height: 18px;">
                        <use xlink:href="#icon-pen"></use>
                    </svg>
                </button>`;
            let delete_button = 
                `<button type="button"
                    data-location-name="${server_response_data.name}" 
                    data-location-id="${server_response_data.id}"
                    class="reset-button x-button delete-location">

                    <svg class="icon" style="width: 13px; height: 13px;">
                        <use xlink:href="#icon-x"></use>
                    </svg>
                </button>`;
            
            let tenantNameToShow = "";
            if (server_response_data.tenant_id == quickverleih.config_params.accessAllTenantsId) {
                tenantNameToShow = "Alle Mandanten";
            } else {
                let selectedTenant = tenants.find(tenant => tenant.id == server_response_data.tenant_id);
                tenantNameToShow = selectedTenant.name;
            }
            dataTable.row.add([
                server_response_data.name,
                server_response_data.address,
                server_response_data.contact_person,
                server_response_data.email,
                tenantNameToShow,
                edit_button,
                delete_button,
            ]).draw(false);

            // reload these 2 functions
            _edit_location();
            _delete_location();
        }

        var _on_add_new_location = function () {
            quickverleih.settings.api.showRolesAndTenants(function (response) {
                var tenants = response.tenants;
                _show_add_new_location_popup(tenants, _location_added_successfull);
            });
        };

        var new_location_button = _content.find('.add-new-location');
        new_location_button.off('click').on('click', _on_add_new_location);
    }

    function _edit_location() {

        var _location_saved_successfull = ($trParent, server_response, tenants) => {
            if(!server_response.ok)
            {
                var message = server_response.message;
                showAlert(message);
                return false;
            }

            var locations_table = _content;
            var dataTable = locations_table.DataTable();
            var rowData = dataTable.row($trParent).data();

            let tenantNameToShow = "";
            if (server_response.data.tenant_id == quickverleih.config_params.accessAllTenantsId) {
                tenantNameToShow = "Alle Mandanten";
            } else {
                let selectedTenant = tenants.find(tenant => tenant.id == server_response.data.tenant_id);
                tenantNameToShow = selectedTenant.name;
            }

            rowData[0] = server_response.data.name;
            rowData[1] = server_response.data.address;
            rowData[2] = server_response.data.contact_person;
            rowData[3] = server_response.data.email;
            rowData[4] = tenantNameToShow,

            dataTable.row($trParent).data(rowData).draw(false);

            // reload these 2 functions
            _edit_location();
            _delete_location();
           
            closePopup();
        }

        var _on_edit_location = function () {
            let $this = $(this);
            var location_id = $this.data("location-id");

            quickverleih.settings.api.getLocation(location_id, (response) => {
                if(!response.ok) {
                    var message = response.message;
                    showAlert(message);
                    return false;
                }

                quickverleih.settings.api.showRolesAndTenants(function (responseFromTenants) {
                    var tenants = responseFromTenants.tenants
                    let location_data = response.location;

                    var popupContent = quickverleih.template.render('./includes/einstellung/templates/location_popup._', {
                        mode : "edit",
                        tenants: tenants,
                        location: location_data,
                    });
                    
        
                    let popup = openPopup(popupContent, '400px');
        
                    let save_button = popup.find('.save-location');
                    save_button.off('click').on('click', function () {
        
                        if (_validateInputs(popup) === false) {
        
                            return;
                        }
        
                        let edited_location_data = _get_popup_data(popup);
                        edited_location_data.id = location_id;

                        quickverleih.settings.api.saveLocation(edited_location_data,
                            (server_response) => {
                                _location_saved_successfull($this.parents('tr').first(), server_response, tenants);
                            }
                        );
                    });
                });
            });
        };

        var edit_location_button = _content.find('.edit-location');
        edit_location_button.off('click').on('click', _on_edit_location);
    }

    function _validateInputs(popup) {

        let ids_to_check = ['#location_name', '#location_address', '#location_contact_person', '#location_email', '#location_tenant_id'];

        let borderKO = '#ff0000';
        let borderOk = '#eaeaea';
        let anyInputEmpty = false;
        
        ids_to_check.map(input_id => {
            let $input = popup.find(input_id);
            let isEmpty = $input.val().length === 0 || $input.val() == 0;
            if(isEmpty) {
                $input.css({ 'border': '1px solid ' + borderKO });
            } else {
                $input.css({ 'border': '1px solid ' + borderOk });
            }

            anyInputEmpty  = anyInputEmpty || isEmpty;
        });

        return !anyInputEmpty;
    }

    function _get_popup_data(popup) {

        let data_elements = ['name', 'address', 'contact_person', 'email', 'tenant_id'];
        let popup_data = {};

        _.each(data_elements, (element) => {
            let $input = popup.find(`#location_${element}`);

            popup_data[element] = $input.val()
        });

        return popup_data;
    }

    
    function _delete_location() {

        var _deleteEmpFn = function () {
            var $elem = $(this);

            var location_id = $elem.data('location-id');

            quickverleih.settings.api.canDeleteLocation(location_id, function (response) {
                if(!response.ok) {
                    var message = response.message;
                    showAlert(message);
                    return false;
                }

                var popupContent = quickverleih.template.render('./includes/einstellung/templates/delete_location_popup._', {});

                var popup = openPopup(popupContent, '500px');

                var confirm_delete_button = popup.find('.delete-location-yes');

                confirm_delete_button.off('click').on('click', function () {
                    quickverleih.settings.api.deleteLocation(location_id, function (response) {
                        if(!response.ok) {
                            var message = response.message;
                            showAlert(message);
                            return false;
                        }

                        var $trParent = $elem.parents('tr').first();

                        var locations_table = _content;
                        var dataTable = locations_table.DataTable();

                        dataTable.row($trParent).remove().draw(false);

                        closePopup();
                    });
                });
            });
        };

        var delete_location_button = _content.find('.delete-location');
        delete_location_button.off('click').on('click', _deleteEmpFn);
    }

    return {
        init: init,
        show_add_new_location_popup: _show_add_new_location_popup,
        show_add_new_tenant_popup: _show_add_new_tenant_popup
    };
})();
